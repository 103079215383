import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";
import {Grid, Typography, CardMedia} from '@material-ui/core'
import Image from 'react-bootstrap/Image'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
      title:{
          fontSize: '2.5em',
          paddingTop: 10,
          paddingBottom: 20,
      },
      banner: {
          fontWeight: 'bold',
          paddingTop: 20
      },
      requirement: {
          paddingLeft: 30,
          paddingTop: 5,
      },
      text:{
        paddingTop: 10,
        paddingBottom: 15,
      }
    }))

const Education = () => {
    const data= useStaticQuery(graphql`query education {
        allCeformaPagesShow8 {
          edges {
            node {
              data {
                page {
                  content {
                    title
                    Banner1
                    ImgPrincipal
                    SecondaryText
                    requirements {
                      requirement
                    }
                    welcomeText
                  }
                }
              }
            }
          }
        }
      }`)
    const classes = useStyles();
return(    
  <Layout>
    <SEO title={data.allCeformaPagesShow8.edges[0].node.data.page.content.title}/>
    <Typography className={classes.title}>{data.allCeformaPagesShow8.edges[0].node.data.page.content.title}</Typography>
    <Grid
    container
    direction="row" 
    justify="center"
    alignItems="flex-start">
        <Grid xs= {3}>
           {/*  <CardMedia 
              image={data.allCeformaPagesShow8.edges[0].node.data.page.content.ImgPrincipal}
              //title={office.Nombre+ '-'+index}
              className={classes.media}
            /> */}
            <Image
              width={'90%'}
              height={'140vh'}
              src={data.allCeformaPagesShow8.edges[0].node.data.page.content.ImgPrincipal}
              thumbnail/>
        </Grid>
        <Grid xs={9}>
          <Typography>
            {data.allCeformaPagesShow8.edges[0].node.data.page.content.welcomeText}
          </Typography>
        </Grid>
    </Grid>
    <Grid>
        <Typography className={classes.text}>
          {data.allCeformaPagesShow8.edges[0].node.data.page.content.SecondaryText}
        </Typography>
        {data.allCeformaPagesShow8.edges[0].node.data.page.content.requirements.map((requirement, index) =>(
            <Typography className={classes.requirement}>{index+1+'. '+requirement.requirement}</Typography>
        ))}
    </Grid>
    <Grid
    container
    justify="center"
    alignItems="center">
        <Typography className={classes.banner}>{data.allCeformaPagesShow8.edges[0].node.data.page.content.Banner1}</Typography>
    </Grid>
  </Layout>
)}

export default Education
